<template>
  <main id="charge">
    <Loading v-if="isLoading" />
    <template v-else>
      <section class="section-top">
        <h1 class="section-top-title">充電完成</h1>
        <div class="section-top-text">
          <p>感謝您的消費，以下是您此次充電的明細</p>
          <p>( 訂單編號：{{ this.record.orderNo }} )</p>
        </div>
      </section>
      <ReceiptList :record="record" />
      <section class="section-main px-1-6-my-1-5">
        <div class="section-main__action">
          <button
            v-if="isFinished"
            type="button"
            class="button is-fullwidth is-link is-rounded"
            disabled
          >
            充電明細發送完成
          </button>
          <button
            v-else
            type="button"
            class="button is-fullwidth is-link is-rounded"
            @click="sendEmail()"
          >
            {{ isProcessing ? "充電明細發送中" : "寄送充電明細給我" }}
          </button>
        </div>
      </section>
    </template>
  </main>
</template>
<script>
import chargingAPI from "../apis/charging";
import ReceiptList from "../components/Receipt/ReceiptList.vue";

import Loading from "../components/Loading.vue";
export default {
  name: "receipt",
  data() {
    return {
      record: {
        chargingId: "",
        orderNo: "",
        chargingType: 0,
        userId: "",
        stationId: "",
        station: "",
        address: "",
        boxId: "",
        box: "",
        connectorId: "",
        connector: "",
        connectorType: "",
        current: 0,
        startTime: "",
        stopTime: "",
        amount: 0,
        unitPrice: 0,
        energy: 0,
        averagePower: 0,
        maxPower: 0,
        paymentMethod: 0,
        cardId: "",
        last4: "",
        usePoints: 0,
        points: 0,
        coins: 0,
        invoiceType: 0,
        invoiceCode: null,
        invoiceTitle: null,
        invoiceAmount: 0,
        invoiceNumber: null,
        invoiceRandomNumber: null,
        invoiceEmail: null,
        invoiceDate: "",
        vehicleId: "",
        vehicle: null,
        notification: 0,
        timeout: 0,
        paid: 0,
        status: 0,
        finished: 0,
        createdTime: "",
      },
      isProcessing: false,
      isFinished: false,
      isLoading: false,
    };
  },
  components: { ReceiptList, Loading },
  created() {
    const { chargingId } = this.$route.params;
    this.getRecord(chargingId);
  },
  methods: {
    async getRecord(chargingid) {
      try {
        this.isLoading = true;
        let { data, status } = await chargingAPI.charging.getChargingRecord({
          chargingid,
        });
        if (!(status >= 200 && status < 300)) {
          throw new Error(status);
        }
        let { amount, startTime, stopTime } = data;
        data = {
          ...data,
          totalTime: new Date(stopTime) - new Date(startTime),
          amount: amount.toLocaleString(),
        };
        this.record = {
          ...this.record,
          ...data,
        };
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async sendEmail() {
      try {
        this.isProcessing = true;
        const { chargingId } = this.$route.params;
        const { status } = await chargingAPI.charging.sendEmail({
          chargingid: chargingId,
        });
        if (!(status >= 200 && status < 300)) {
          throw new Error(status);
        }
        this.isProcessing = false;
        this.isFinished = true;
      } catch (error) {
        console.log(error);
        this.isProcessing = false;
      }
    },
  },
};
</script>
