<template>
  <section class="section-receipt">
    <div class="receipt">
      <ul class="receipt-list">
        <li class="receipt-item">
          <div class="receipt-item__label">
            <div class="receipt-item__label-icon">
              <inline-svg
                :src="require('../../assets/icon/svg/charging-kW.svg')"
              />
            </div>
            <div class="receipt-item__label-text">充電時間</div>
          </div>
          <div class="receipt-item__content">
            <div class="receipt-item__content-description">
              {{ this.record.startTime | formatHHMMSS }} ~
              {{ this.record.stopTime | formatHHMMSS }}
            </div>
            <div class="receipt-item__content-value">
              <small>共</small
              >{{ this.record.totalTime | convertMillisecondsDurationsToTime }}
            </div>
          </div>
        </li>
        <li class="receipt-item">
          <div class="receipt-item__label">
            <div class="receipt-item__label-icon">
              <inline-svg
                :src="require('../../assets/icon/svg/charging-kWh.svg')"
              />
            </div>
            <div class="receipt-item__label-text">充電電量</div>
          </div>
          <div class="receipt-item__content">
            <ul class="receipt-item__content-description">
              <li>
                <p>最大功率：{{ this.record.maxPower | toFixed }} kW</p>
              </li>
              <li>
                <p>平均功率：{{ this.record.averagePower | toFixed }} kW</p>
              </li>
            </ul>
            <div class="receipt-item__content-value">
              {{ this.record.energy | energyToKwh }} <small>kWh</small>
            </div>
          </div>
        </li>
        <li class="receipt-item">
          <div class="receipt-item__label">
            <div class="receipt-item__label-icon">
              <inline-svg
                :src="require('../../assets/icon/svg/charging-dollar.svg')"
              />
            </div>
            <div class="receipt-item__label-text">
              充電費率：{{ this.record.unitPrice }}元
            </div>
          </div>
          <div class="receipt-item__content">
            <ul class="receipt-item__content-description">
              <li>
                <p>充電金額</p>
              </li>
            </ul>
            <div class="receipt-item__content-value">
              {{ this.record.amount }} <small>元</small>
            </div>
          </div>
        </li>
        <li class="receipt-summary">
          <div class="receipt-summary__content">
            <div class="receipt-summary__content-description">應付金額</div>
            <div class="receipt-summary__content-value">
              <small>NT$</small>
              <p>{{ this.record.amount }}</p>
              <small>元</small>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(tz);
import { timeFilter, powerFilter, energyFilter } from "../../utils/mixins";
export default {
  name: "RecordList",
  props: {
    record: {
      type: Object,
      require: true,
    },
  },
  mixins: [timeFilter, powerFilter, energyFilter],
};
</script>
